<template>
	<div class="case">
		<div v-if="caseList.length>0">
			<div v-for="(item,index) in caseList">
				<div class="tit">{{item.name}}</div>
				<div class="p1">
					<p> {{item.desc}}</p>
				</div>
				
				<div class="ban">
					<el-carousel :interval="5000" type="card" height="280px" class="banCont" arrow="never"  ref="cardShow">
						<el-carousel-item v-for="item2,index2 in item.performance" :key="index2">
						  <img :src="item2" alt="" class="banImg">
						</el-carousel-item>
					</el-carousel>
					<!-- 上面的ref里一致 -->
					<div class="arr arr1" v-show="item.performance.length>1" @click="arrLeft('cardShow',index)"></div>
					<div class="arr arr2" v-show="item.performance.length>1" @click="arrRight('cardShow',index)"></div>
				</div>
			</div>
			
		</div>
		
		<!-- 登陆无内容 -->
		<div class="notData" v-else>
			 <img src="@/assets/images/ip/nodata.png" alt="" >
			 <p class="p1">稍后上线，敬请期待</p>
			 <p class="p1"><a class="link" @click.stop="qrcode_show(1)">联系客服，</a> 了解更多内容</p>
		</div>
	</div>
</template>

<script>
	export default {
		props:['qrcode_show'],
		data(){
			return{
				iid:'',
				caseList:[]
				
			}
		},
		created(){
			this.iid = this.$route.query.iid;
			this.getCase();
		},
		watch:{
			$route(){
				if(this.$route.query.iid){
					this.iid = this.$route.query.iid
					this.getCase();
				}
			},
		},
		methods:{
			getCase(){
				this.API.cooperation_list({"ipid":this.iid},(res)=>{
					this.caseList = res.data;
				})
			},
			arrLeft(cardname,index){
				this.$refs[cardname][index].prev();
			},
			arrRight(cardname,index){
				this.$refs[cardname][index].next();
			},
			qrcode_show(num){
				if (this.qrcode_show) {
				  this.qrcode_show(num);
				}
			},
			
			
		},
		
	}
</script>

<style scoped lang="less">
	.notlogin,.notData{text-align: center;padding: 110px 0;
		.p1,.p2{color:#606060;font-size: 14px;line-height: 22px;padding-bottom: 8px;
			.link{color: #F67F36;}
			img{margin-top: 90px;}
		}
	}
	.case{padding: 30px;}
	.case .tit{color: #222222;font-size: 16px;font-weight: bold;text-align: center;padding: 16px 0;;}
	.case .p1{color:#606060;font-size: 14px;line-height: 26px;}
	.case .p1 p{color: #606060;font-size: 14px;line-height: 26px;text-indent: 28px;}
	.case .ban{padding: 10px 0 50px;position: relative;}
	.case .ban .banCont{ width: 608px;margin: 0 auto;}
	.case .ban .banImg{width: 550px;height: 280px;box-shadow: 0px 10px 20px 0px rgba(54, 66, 106, 0.18);}
	.ban .el-carousel__item--card{width: 550px;margin-left: -120px;}
	.case .ban .arr{width: 22px;height: 40px;position: absolute;top:50%;margin-top: -20px;opacity: 0.7;cursor: pointer;}
	.case .ban .arr:hover{opacity: 1;}
	.case .ban .arr1{background-image: url(../../assets/images/ip/arr1.jpg);left: 48px;;}
	.case .ban .arr2{background-image: url(../../assets/images/ip/arr2.jpg);right: 48px;}
	.case :deep(.el-carousel__button) {width: 30px;height: 2px;background: #F5630B;}
</style>