<template>
	<div class="introduce">
		
		
		
		
		<template  v-if="introduceList.length>0">
					
			<div v-for="item,index in introduceList" :key="index">
				
				<div v-if="item.type ==4">
					<!-- 详情 -->
					<div class="details">
						<div class="line1">
							 {{item.column_name}} 
							<!-- <span class="txt">资料卡</span> -->
						</div>
						<div class="tables">
							<!-- <div class="tr" > -->
								<div v-for="(value, key,index2) in item.contentArr"  :key="key"  class="tr">
									<div class="td" v-for="(value2, key2)  in value ">
									   <div class="lable">{{Object.keys(value2)[0]}}</div>
									   <div class="txt">{{value2[Object.keys(value2)[0]]}}</div>
									</div>
								</div>
							  
							   
							<!-- </div> -->
						   
						</div>
					</div>
				</div>
				
				<div v-if="item.type ==1">
					<!-- 主要角色 -->
					<div class="actors">
						<div class="line1">{{item.column_name}}</div>
						
						<div class="actor" v-for="item2,index2 in item.content" :key="index2">
							<img class="left" :src="item2.image" />
							<div class="right">
								<div class="name">{{item2.title}}</div>
								<div class="desc">{{item2.content}}</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<div v-if="item.type ==5">
					<!-- 主要参演作品 -->
					<div class="actors2">
						<div class="line1">{{item.column_name}}</div>
						
						<div class="actorlist">
							<div class="actor" v-for="item2,index2 in item.content" :key="index2">
								<img class="pic" :src="item2.image" />
								<div class="txts">
									<el-tooltip placement="top"  effect="light">
									    <template #content>{{item2.title}}</template>
										<div class="name">{{item2.title}}</div>
									</el-tooltip>
									<el-tooltip placement="top"  effect="light">
									    <template #content>{{item2.content}}</template>
										<div class="desc">{{item2.content}}</div>
									</el-tooltip>
									
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
				<div v-if="item.type ==2">
					<!-- 作品背景 -->
					<div class="stories">
						<div class="line1">{{item.column_name}}</div>
						<div class="desc"  v-html="item.content"></div>
					</div>
					
				</div>
				
				<div v-if="item.type ==3">
					<!-- 作品图集 -->
					<div class="albums">
						<div class="line1">{{item.column_name}}</div>
						<div class="ban">
							<el-carousel :interval="5000" type="card" height="280px" class="banCont" arrow="never"  ref="cardShow">
								<el-carousel-item v-for="(item2) in item.content" :key="item2">
								<img :src="item2" alt="" class="banImg">
								</el-carousel-item>
							</el-carousel>
							<!-- 上面的ref里一致 -->
							<div class="arr arr1" v-show="item.content.length>1" @click="arrLeft('cardShow',item.type3len)"></div>
							<div class="arr arr2" v-show="item.content.length>1" @click="arrRight('cardShow',item.type3len)"></div>
						</div>
					</div>
					 
				</div>
				
			</div>
			
		</template>
		
		<!-- 登陆无内容 -->
		<div class="notData" v-else>
			 <img src="@/assets/images/ip/nodata.png" alt="" >
			 <p class="p1">稍后上线，敬请期待</p>
			 <p class="p1"><a class="link" @click.stop="qrcode_show(1)">联系客服，</a> 了解更多内容</p>
		</div>
	
	</div>
</template>

<script>
	export default {
		props:['qrcode_show'],
		data(){
			return {
				ban:[
				    {img:require('@/assets/images/thum/ban1.jpg')},
				    {img:require('@/assets/images/thum/pic1.jpg')},
				    {img:require('@/assets/images/thum/pic2.jpg')}, 
				],
				introduceList:[],
				iid :'',
			}
		},
		watch:{
			$route(){
				if(this.$route.query.iid){
					this.iid = this.$route.query.iid
					this.getDetail();
				}
			},
		},
		created(){
			this.iid = this.$route.query.iid;
			this.getDetail();
		},
		
		methods:{
			getDetail(){
				this.API.introduce_list({"ipid":this.iid},(res)=>{
					var arr1 = []
					var arr2 = [];
					var type3len = 0;
					 res.data.forEach((item,index)=>{
						 if(item.type ==4){
							 // 4 代表人员资料 切分为两两一组  适用结构
							var keys = Object.keys(item.content);
							 keys.forEach((item2)=>{
							 	if(item.content[item2]!=''){
							 		arr1.push({[item2]:item.content[item2]})
							 	}
							 })
							 for(var i=0;i<arr1.length;i+=2){
							 	arr2.push(arr1.slice(i,i+2))
							 }
							item.contentArr = arr2
						 }
						 if(item.type==3){
							 item.type3len = type3len
							 type3len++
						 }
						 
					 })
					this.introduceList = res.data;
				})
			},
			arrLeft(cardname,index){
				console.log(cardname,index);
				console.log(this.$refs[cardname]);
				this.$refs[cardname][index].prev();
			},
			arrRight(cardname,index){
				this.$refs[cardname][index].next();
			},
			
			qrcode_show(num){
				if (this.qrcode_show) {
				  this.qrcode_show(num);
				}
			},
		},
		
	}
</script>

<style scoped lang="less">
	.notlogin,.notData{text-align: center;padding: 110px 0;border-bottom: 8px solid #f8f9fa;
		.p1,.p2{color:#606060;font-size: 14px;line-height: 22px;padding-bottom: 8px;
			.link{color: #F67F36;}
			img{margin-top: 90px;}
		}
	}

	
 .details{border-bottom: 8px solid #F8F9FA;
 padding:36px 28px; 
	// .line{margin:0 12px 0 90px;padding:15px 0 10px 0;
	// 	.name{ height: 22px;font-size: 16px; font-weight: 500;color: #0B0B0B;line-height: 22px;}
	// 	.txt{ height: 16px;font-size: 12px; font-weight: 400;color: #222222;line-height: 16px;margin-left:12px;}
	// }
	.tables{width:713px;margin: 0 auto;padding-bottom: 35px;
		.tr{height: 40px;_border-bottom: 2px dashed rgba(158, 164, 186, 0.7);
			.td{display: inline-block;width: 50%;padding-top: 12px;
				.lable{width: 82px;height: 17px;font-size: 12px; font-weight: 400;color: #888888;line-height: 17px;display:inline-block;margin-right: 25px;text-align: right;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
				.txt{width: 240px;height: 17px;font-size: 12px; font-weight: 400;color: #222222;line-height: 17px;display:inline-block;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
			}
		}
	}
}
.line1{height: 22px;font-size: 16px; font-weight: 500;color: #222222;line-height: 22px;padding-left:6px;
	&:after{content:' ';position:absolute;top:5px;left:0; width: 2px;height: 14px;background: #F5630B;border-radius: 1px;}
}

.actors{ padding:36px 28px; border-bottom: 8px solid #F8F9FA;
	.actor{display: flex;padding:25px 0;border-bottom:1px solid #edeeef;
		.left{border-radius: 5px;width: 270px;height: 160px;object-fit: cover;
			&:hover{opacity:0.9;}
		}
		.right{margin-left:28px;
			.name{margin:16px 0 20px 0;font-size: 16px; font-weight: 500;color: #222222; }
			// .desc{height: 72px;font-size: 14px; font-weight: 400;color: #606060;line-height: 24px;text-align: justify;overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3;}
			.desc{font-size: 14px; font-weight: 400;color: #606060;line-height: 24px;text-align: justify;display: -webkit-box;}
			
		} 
		&:last-child{border-bottom: 0;padding-bottom: 0;}
	} 
}
.actors2{
	 padding:36px 28px; border-bottom: 8px solid #F8F9FA;
	.actor{margin:24px 18px 0 0;width: 198px;display: inline-block;
		.pic{width: 100%;height: 243px;border-radius: 4px;border: 1px solid #E3E6EC;object-fit: cover;}
		.txts{
			.name{
				font-size: 16px;
				color: #222222;
				padding: 15px 0 8px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.desc{
				font-size: 14px;
				color: #888888;
				line-height: 20px;
				// 添加固定高度，解决对齐问题
				height: 20px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		&:nth-child(4n){margin-right: 0;}
	}
	
}

.stories{ padding:36px 28px; border-bottom: 8px solid #F8F9FA;
	.desc{width:;font-size: 14px; font-weight: 400;color: #606060;line-height: 26px;margin: 25px 0 30px 0;
		:deep(p), :deep(p span), :deep(*){font-size: 14px;line-height: 26px;}
	}
	.picBox{width: 100%;text-align: center;
		.poster{width: 628px;height: 210px;}
	}
}
.albums{
	padding:36px 28px; 
	border-bottom: 8px solid #F8F9FA;
	.ban{
		padding: 10px 0 50px;
		position: relative;
		.banCont{ width: 608px;margin: 0 auto;}
		.banImg{height: 280px;margin: 0 auto;display: block;;box-shadow: 0px 10px 20px 0px rgba(54, 66, 106, 0.18);}
		.el-carousel__item--card{width: 550px;margin-left: -120px;background-color: #2A2A2A;}
		.arr{width: 22px;height: 40px;position: absolute;top:50%;margin-top: -20px;opacity: 0.7;cursor: pointer;
			&:hover{opacity: 1;}
		}
		.arr1{background-image: url(../../assets/images/ip/arr1.jpg);left: 48px;;}
		.arr2{background-image: url(../../assets/images/ip/arr2.jpg);right: 48px;}

		:deep(.el-carousel__button) {width: 30px;height: 2px;background: #F5630B;}
	}    
}


</style>