<!-- 详情页面 -->
<template>
  <div class="ipDetail">
    <!-- 面包屑 -->
    <div class="bread">
      <div class="b1"><router-link to="/ip">IP筛选</router-link></div>
      <div class="mid">&gt;</div>
      <div class="b2">IP介绍</div>
    </div>

    <!-- 头部详情 -->
    <div ref="headContentRef" class="headContent">
      <img :src="detail.logo" alt="" class="head" />
      <div class="left">
        <div class="line1">
          <div class="name">{{ detail.name }}</div>
          <div class="recog">
            <span class="icon"></span>
            <span class="txt">直联认证 </span>
            <a
              v-if="detail.authorization_certificate"
              class="certificate"
              @click="showpic = true"
              >认证证书</a
            >
            <el-dialog v-model="showpic" class="bigPicBox">
              <img
                :src="detail.authorization_certificate"
                alt=""
                class="bigPic"
              />
            </el-dialog>
          </div>
        </div>
        <div class="tags">
          <div class="tag">
            {{ detail.ip_type_level1 }} / {{ detail.ip_type_level2 }}
          </div>
        </div>
        <div class="lables">
          <div v-for="tag in detail.tags" :key="tag" class="lable">
            {{ tag }}
          </div>
        </div>
        <div class="desc">
          {{ detail.desc }}
        </div>
        <div class="fans" v-if="platformFans.length > 0">
          <div class="total_fans">
            全网粉丝数：<span
              >{{
                numformatHandle(+this.detail.fansTotalCount, { length: 1 })
              }}万</span
            >
          </div>
          <div class="platform_fans">
            <div v-for="platform in platformFans" :key="platform.label">
              <img alt="" :src="platform.icon" />
              <span>{{ platform.label }}：</span>
              <span
                >{{
                  numformatHandle(
                    +this.detail.fansDetail[platform.value].fans_count,
                    { length: 1 }
                  )
                }}万</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="btns">
          <div
            class="collection"
            :class="detail.collected == 0 ? '' : 'active'"
            @click="collection_handler"
          >
            <i></i><span>收藏</span>
          </div>
          <div
            class="compare"
            @click.stop="
              qrcode_show(1);
              clickTracker(16);
            "
          >
            <span>IP对比</span>
          </div>
          <div
            class="contectIp"
            @click.stop="
              qrcode_show(1);
              clickTracker(17);
              contactIP();
            "
          >
            <i></i><span>联系IP</span>
          </div>
          <div
            class="shareIp"
            @click.stop="
              qrcode_show(2, detail.id);
              clickTracker(18);
            "
          >
            <i></i><span>分享IP</span>
          </div>
        </div>
        <div class="copDetail">
          <div class="line">
            <div class="lable">合作内容：</div>
            <!-- <div class="txt">{{detail.cooperation_type.toString()}}</div> -->
            <el-tooltip placement="top" effect="light">
              <template #content
                ><template v-for="(item, index) in detail.cooperation_type"
                  >{{ item }}
                  <template v-if="index != detail.cooperation_type.length - 1"
                    >，</template
                  ></template
                ></template
              >
              <div class="txt">
                <template v-for="(item, index) in detail.cooperation_type"
                  >{{ item }}
                  <template v-if="index != detail.cooperation_type.length - 1"
                    >，</template
                  ></template
                >
              </div>
            </el-tooltip>
          </div>
          <div class="line">
            <div class="lable">价格区间：</div>
            <!-- 认证后且是品牌方展示价格或真的没有价格 -->
            <div
              class="txt"
              v-if="
                detail.cooperation_price_down == '' ||
                detail.cooperation_price_up == ''
              "
            >
              --
            </div>
            <div class="txt" v-else-if ="showPrice">
              {{ detail.cooperation_price_down }} -
              {{ detail.cooperation_price_up }}
            </div>
            <div v-else class="price_btn" @click=" clickPrice ">
              获取价格
            </div>
          </div>

          <div class="line">
            <div class="lable">付款形式：</div>
            <div class="txt">{{ detail.pay_type }}</div>
          </div>
          <div class="line">
            <div class="lable">物料审核周期：</div>
            <div class="txt">{{ detail.material_audit_range }}</div>
          </div>
          <div class="line">
            <div class="lable">合作周期：</div>
            <div class="txt">
              <template v-for="(item, index) in detail.cooperation_range">
                {{ item }}
                <template v-if="index != detail.cooperation_range.length - 1"
                  >，</template
                >
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 内容部分 -->
    <div class="dtContent">
      <!-- 左侧详情 -->
      <div class="leftTabs">
        <!-- <el-affix :offset="64" z-index="99"> -->
        <div class="tabs_wrap">
          <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane label="IP介绍" name="detailIp">
              <!-- IP介绍 -->
              <div id="detailIp" class="tabpanel-box">
                <notLogin v-if="!isShowPageData"></notLogin>
                <detailIntroduce
                  v-else
                  :qrcode_show="qrcode_show"
                  ref="detailIntroduce"
                ></detailIntroduce>
              </div>
            </el-tab-pane>
            <el-tab-pane label="未来动态" name="detailDynamic">
              <!-- 未来动态 -->
              <div id="detailDynamic" class="dynamic">
                <notLogin v-if="!isShowPageData"></notLogin>
                <futureDynamic v-else :qrcode_show="qrcode_show" />
              </div>
            </el-tab-pane>
            <el-tab-pane label="合作历史" name="detailCase">
              <!-- 合作案例 -->
              <div id="detailCase" class="tabpanel-box">
                <notLogin v-if="!isShowPageData"></notLogin>
                <cooperationCase
                  v-else
                  :qrcode_show="qrcode_show"
                  ref="cooperationCase"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="舆论印象" name="detailData">
              <!-- 数据表现 -->
              <div id="detailData" class="data">
                <img
                  src="../assets/images/ip/comingsoon.jpg"
                  alt=""
                  srcset=""
                />
                <p class="p1">
                  <a class="link" @click.stop="qrcode_show(1)">联系客服，</a>
                  了解更多内容
                </p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="人群画像" name="detailFans">
              <!-- 粉丝画像 -->
              <div id="detailFans" class="fans">
                <img
                  src="../assets/images/ip/comingsoon.jpg"
                  alt=""
                  srcset=""
                />
                <p class="p1">
                  <a class="link" @click.stop="qrcode_show(1)">联系客服，</a>
                  了解更多内容
                </p>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- </el-affix> -->
      </div>
      <!-- 右侧猜你喜欢 ... ...  -->
      <rightFavorite ref="rightFavorite" />
    </div>

    <transition name="fadeInLeft">
      <qrcode ref="qrcode"></qrcode>
    </transition>
  </div>
  <footers />
</template>
<script>
import rightFavorite from "@/views/ipdetail/rightFavorite.vue";
import cooperationCase from "@/views/ipdetail/cooperationCase.vue";
import detailIntroduce from "@/views/ipdetail/detailIntroduce.vue";
import notLogin from "@/components/notLogin.vue";
import futureDynamic from "@/views/ipdetail/futureDynamic.vue";
import qrcode from "@/views/home/qrcode.vue";
import footers from "@/components/foot.vue";
import useStore from "@/store/index.js";
import { PLATFORM_OPTIONS } from "@/common/enum";
import { numformatHandle } from "@/common/numberUtil";
import tracker from "@/common/tracker.js";
export default {
  data() {
    return {
      activeName: "detailIp",
      showpic: false,
      detail: {
        cooperation_price_down: 0,
        cooperation_price_up: 0,
        fansDetail: {},
      },
      platformOption: PLATFORM_OPTIONS,
      hasIpRights: false,
      numformatHandle,
      showPrice:false
    };
  },
  components: {
    rightFavorite,
    cooperationCase,
    detailIntroduce,
    notLogin,
    qrcode,
    footers,
    futureDynamic,
  },
  methods: {
    clickPrice(){
      this.showPrice = true;
      this.clickTracker(19)
    },
    clickTracker(opType) {
      tracker({
        opType,
        ipId: this.iid,
      });
    },
    contactIP() {
      this.API.contact_notice({ ipid: this.iid });
    },
    collection_handler() {
      if (this.detail.collected == 0) {
        // 收藏
        console.log(0);
        this.API.collect({ ipid: this.iid }, (res) => {
          if (res.code == 0) {
            this.detail.collected = 1;
          } else if (res.code == 5) {
            this.store.setCurComp("login");
          }
        });
      } else {
        // 取消收藏
        this.API.cancel_collect({ ipid: this.iid }, (res) => {
          if (res.code == 0) {
            this.detail.collected = 0;
          } else if (res.code == 5) {
            this.store.setCurComp("login");
          }
        });
      }
      tracker({
        url: this.$route.path,
        opType: this.detail.collected == 0 ? 3 : 4,
        ipId: this.iid,
      });
    },
    showLogin() {
      this.store.setCurComp("login");
    },
    showRegister() {
      this.store.setCurComp("register");
    },
    qrcode_show(num, id) {
      this.$refs.qrcode.showQrcode(num, id);
    },
    bigNumberTransform(value) {
      if (typeof value == "string") {
        value = Number(value);
      }
      let unit = "";
      let k = 10000;
      let sizes = ["", "万", "亿", "万亿"];
      let i;
      if (value < k) {
        value = value;
      } else {
        i = Math.floor(Math.log(value) / Math.log(k));
        value = (value / Math.pow(k, i)).toFixed(2) / 1;
        unit = sizes[i];
      }
      return value + unit;
    },
    arrLeft(cardname) {
      this.$refs[cardname].prev();
    },
    arrRight(cardname) {
      this.$refs[cardname].next();
    },
    createComparisonFunction(propertyName) {
      return function (object1, object2) {
        var value1 = object1[propertyName];
        var value2 = object2[propertyName];

        if (Number(value1) < Number(value2)) {
          return -1;
        } else if (Number(value1) > Number(value2)) {
          return 1;
        } else {
          return 0;
        }
      };
    },
    getDetail() {
      this.API.ip_detail({ ipid: this.iid }, (res) => {
        if (res.code == 0) {
          this.detail = res.data;
        }
      });
    },
    async ipRight() {
      const res = await this.API.check_ip_right({ ipid: this.iid });
      const { code } = res.data;
      if (code === 15) {
        if (this.logins.checkStatus != 2) {
          //未认证弹出认证弹窗
          this.store.setCurComp("authentication");
        } else {
          //已认证弹出数量限制弹窗
          this.store.setCurComp("ipQuotaLimit");
        }
      } else if (code === 5) {
        this.store.setCurComp("guide");
      }
      if (code === 0) {
        this.hasIpRights = true;
        this.getDetail();
        if (!+this.$route.query?.preview) {
          tracker({
            url: this.$route.path,
            opType: 5,
            ipId: this.$route.query.iid,
          });
        }
      }
    },
  },
  watch: {
    $route() {
      if (this.$route.query.iid) {
        this.iid = this.$route.query.iid;
        this.ipRight();
      }
    },
  },
  computed: {
    platformFans() {
      return this.platformOption.filter((v) => {
        return this.detail.fansDetail[v.value];
      });
    },
    isShowPageData() {
      return this.hasIpRights;
    },
  },
  created() {
    this.store = useStore();
    this.logins = this.store.logins;
    this.iid = this.$route.query.iid;
    this.activeName = this.$route.query?.activeName || "detailIp";
    this.ipRight();
  },
};
</script>
<style lang="less">
.tabs_wrap {
  .el-tabs__header {
    margin: 0;
  }
}
</style>
<style lang="less" scoped>
.tabs_wrap {
  background: #fff;
}
.notlogin,
.notData {
  text-align: center;
  padding: 110px 0;
  .p1,
  .p2 {
    color: #606060;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
    .link {
      color: #f67f36;
    }
    img {
      margin-top: 90px;
    }
  }
}

.ipDetail {
  width: 1340px;
  margin: 0 auto;
  padding: 0 20px;
  .bread {
    .b1 {
      display: inline-block;
      a {
        font-size: 16px;
        font-weight: 500;
        color: #5d5d5d;
        line-height: 22px;
        &:hover,
        &:active {
          color: #ea5504;
        }
      }
    }
    .mid {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #5d5d5d;
      line-height: 22px;
      margin: 0 15px;
    }
    .b2 {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      line-height: 48px;
      color: #0b0b0b;
    }
  }
  .headContent {
    min-height: 300px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 26px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    .head {
      width: 124px;
      height: 124px;
      border-radius: 20px;
      &:hover {
        opacity: 0.9;
      }
    }
    .left {
      width: 643px;
      margin-left: 16px;
      .line1 {
        display: flex;
        height: 22px;
        .name {
          height: 22px;
          font-size: 16px;
          font-weight: 500;
          color: #0b0b0b;
          line-height: 22px;
        }
        .recog {
          .icon {
            margin: 0 9px 0 42px;
            background: url(../assets/images/ip/recog.jpg);
            width: 16px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
          }
          
          .txt {
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: rgba(4, 4, 4, 0.6);
            font-size: 12px;
          }
          .certificate {
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: rgba(4, 4, 4, 0.6);
            font-size: 12px;
            margin-left: 10px;
          }
          .bigPicBox {
            text-align: center;
          }
          .bigPic {
            max-width: 1000px;
            max-height: 600px;
          }
          :deep(.el-dialog__body) {
            text-align: center;
          }
          :deep(.el-dialog) {
            text-align: center;
            position: absolute;
            width: auto;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            margin: 0;
          }
        }
      }
      .tags {
        margin: 6px 0 14px 0;
        .tag {
          border-radius: 2px;
          border: 1px solid rgba(245, 99, 11, 0.3);
          padding: 3px 8px;
          font-size: 12px;
          font-weight: 400;
          color: #ea5504;
          line-height: 16px;
          display: inline-block;
        }
      }
      .lables {
        .lable {
          border-radius: 2px;
          border: 1px solid #6a7084;
          padding: 3px 8px;
          font-size: 12px;
          font-weight: 400;
          color: #6a7084;
          line-height: 16px;
          display: inline-block;
          margin: 0 8px 7px 0;
        }
      }
      .desc {
        width: 640px;
        height: 94px;
        font-weight: 400;
        color: #888888;
        line-height: 22px;
        font-size: 14px;
        text-align: justify;
      }
      .fans {
        margin-top: 16px;
        color: #888888;
        .total_fans {
          font-size: 14px;
          margin-bottom: 11px;
          > span {
            font-size: 14px;
            color: #222222;
          }
        }
        .platform_fans {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          > div {
            width: 25%;
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            > img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
            > span:first-of-type {
              font-size: 14px;
            }
            > span:last-of-type {
              font-size: 14px;
              color: #222222;
            }
          }
        }
      }
    }
    .right {
      width: 508px;
      margin-left: 25px;
      .btns {
        margin-bottom: 26px;
        display: flex;
        width: 435px;
        justify-content: space-between;
        margin-left: 11px;
        div {
          color: #ea5504;
          border: 1px solid #ea5504;
          border-radius: 4px;
          width: 86px;
          height: 32px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.active {
            color: #ffffff;
            background: #ea5504;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-left: 7px;
            height: 20px;
          }
        }
        .collection {
          i {
            background: url(../assets/images/index/i_add.png);
            background-size: cover;
            width: 16px;
            height: 16px;
            display: inline-block;
          }
          span:before {
            content: "未";
            font-size: 14px;
          }
        }
        .collection.active span:before {
          content: "已";
        }
        .compare {
        }
        .contectIp {
          i {
            background: url(../assets/images/ip/smile_.png);
            background-size: cover;
            width: 16px;
            height: 17px;
            display: inline-block;
          }
        }
        .shareIp {
          i {
            background: url(../assets/images/ip/share_.png);
            background-size: cover;
            width: 16px;
            height: 16px;
            display: inline-block;
          }
        }
      }

      .copDetail {
        width: 450px;
        background: #fffdf8;
        border-radius: 6px;
        border: 1px solid #ffefc5;
        padding: 38px;
        .line {
          margin-bottom: 10px;
          display: flex;
          // align-items: center;
          .lable {
            text-align: right;
            width: 100px;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #585d70;
            line-height: 20px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .price_btn{
            font-size: 14px;
            color: #EA5504;
            line-height: 20px;
            text-decoration:underline;
            display: inline-block;
            height: 20px;
            margin-left: 14px;
            cursor: pointer;
          }
          .txt {
            width: 240px;
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #2e3036;
            line-height: 20px;
            display: inline-block;
            margin-left: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .dtContent {
    display: flex;
    margin-bottom: 50px;
    .leftTabs {
      background: #ffffff;
      margin-top: 12px;
      width: 902px;
      flex: 1;
      :deep(.el-tabs__content) {
        border-radius: 6px;
      }
      :deep(.el-tabs__nav) {
        display: flex;
        justify-content: space-around;
        width: 100%;
        user-select: none;
      }
      :deep(.is-active),
      :deep(.el-tabs__item:hover) {
        color: #ea5504;
      }
      :deep(.el-tabs__active-bar) {
        background-color: #ea5504;
        bottom: 1px;
      }
      :deep(.el-tabs__item) {
        line-height: 50px;
        height: 50px;
      }
      :deep(.el-tabs__nav-wrap::after) {
        height: 1px;
        background-color: #eff0f4;
      }
      .data,
      .fans {
        text-align: center;
        padding-bottom: 100px;
        border-top: 8px solid #f8f9fa;
        .p1 {
          color: #3d3d3d;
          font-size: 14px;
          .link {
            color: #f67f36;
          }
        }
        img {
          margin-top: 90px;
        }
      }
      .dynamic {
        border-bottom: 8px solid #f8f9fa;
      }
      .tabpanel-box {
        border-top: 8px solid #f8f9fa;
      }
    }
  }
}
</style>
