<template>
	<div class="rightFavorite">
		<div class="tit">
			<span class="left">猜你喜欢</span>
			<span v-show="total>6" class="right" @click="changeNext">换一换</span>
		</div>
		<div class="list">
			<div v-if="list.length>0">
				<a class="li" to="'/detail" :data=' `{"iid":"${item.id}" }` ' v-for="item in list" :key="item.id"   @click="linkTo">
					<img :src="item.logo" alt="" class="small">
					<div class="rightcont">
						<div class="tit2"><p>{{item.name}} </p><span class="tag">{{item.ip_type_level1}}</span></div>
						
						<el-tooltip placement="top"  effect="light">
						    <template #content>{{item.desc}}</template>
						   <p class="desc">{{item.desc}} </p>
						</el-tooltip>
					</div>
				</a>
			</div>
			<div v-else class="noData">暂无数据</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				list:[],
				page:1,
				total:0,
				pageSize:6,//请求4条数据 每次
				iid:'',
			}
		},
		created(){
			this.iid = this.$route.query.iid;
			this.changeNext();
		},
		watch:{
			$route(){
				if(this.$route.query.iid){
					this.iid = this.$route.query.iid;
					this.page=1;
					this.changeNext();
				}
			},
		},
		methods:{
			changeNext(){
				this.API.guess_you_like({ipid:this.iid,page:this.page,pageSize:this.pageSize},(res)=>{
					this.list = res.data.list;
					this.total = res.data.total;
					// 判断当前pageXlen 大于等于总数 则 重置page
					if(this.pageSize* this.page >= this.total){
						this.page = 1;
					}else{
						this.page++
					}
					
				})
			},
			linkTo(e){
				let to = e.currentTarget.getAttribute("to")
				let data = e.currentTarget.getAttribute("data")
				this.utils.goto(this,to,JSON.parse(data))
			},
		},
		
	}
</script>

<style scoped>
	.rightFavorite{width: 382px;margin-top: 12px;margin-left: 16px;background: #FFFFFF;border-radius: 6px; align-self: flex-start;padding:6px 24px; flex:none;}
	.rightFavorite .tit{display: flex;justify-content: space-between;}
	.rightFavorite .tit .left{font-weight: bold;font-size: 14px;color: #222;padding: 10px 0;}
	.rightFavorite .tit .right{font-size: 12px;color: #888;padding: 10px 0;cursor: pointer;}
	.rightFavorite .list{}
	.rightFavorite .noData{text-align: center;padding: 50px;color: #999;}
	.rightFavorite .list .li{margin-top: 20px;padding: 10px 0;display: flex;}
	.rightFavorite .list .li .small{width: 50px;height: 50px;margin-right: 22px;flex: none;}
	.rightFavorite .list .li .tit2{font-size: 14px;color: #222;}
	.rightFavorite .list .li .tit2 p{display: inline-block;max-width: 120px;overflow:hidden;white-space: nowrap; text-overflow:ellipsis;font-weight: bold;;}
	.rightFavorite .list .li .tag{color: #EA5504;border: 1px solid rgba(245, 99, 11, 0.3);border-radius: 2px;padding: 3px 5px;margin-left: 20px;}
	.rightFavorite .list .li .desc{color: #888;font-size: 12px;line-height: 18px;height: 36px;margin-top: 6px;overflow:hidden; text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:2;}
	
	
</style>