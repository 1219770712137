<template>
  <div v-if="dataList.length > 0">
    <div class="item" v-for="item in dataList" :key="item.id">
      <futureDynamicCard :data="item" />
    </div>
  </div>
  <!-- 登陆无内容 -->
  <div class="notData" v-else>
    <img src="@/assets/images/ip/nodata.png" alt="" />
    <p class="p1">稍后上线，敬请期待</p>
    <p class="p1">
      <a class="link" @click.stop="qrcode_show(1)">联系客服，</a> 了解更多内容
    </p>
  </div>
</template>

<script>
import futureDynamicCard from "@/components/futureDynamicCard.vue";
export default {
  props:['qrcode_show'],
  components: {
    futureDynamicCard,
  },
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const { iid = "" } = this.$route.query;
      await this.API.dynamic_list({ ipid: iid }, (res) => {
        const { code, data } = res;
        if (code === 0) {
          this.dataList = data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  border-top: 8px solid #f8f9fa;
}
.notlogin,
.notData {
  text-align: center;
  padding: 110px 0;
  .p1,
  .p2 {
    color: #606060;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
    .link {
      color: #f67f36;
    }
    img {
      margin-top: 90px;
    }
  }
}
</style>
