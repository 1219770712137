<template>
  <div class="notlogin">
    <img src="@/assets/images/ip/notlogin.png" alt="" />
    <p class="p1">此内容需登录才可查看</p>
    <p class="p1">您可查看Banner中推荐的内容</p>
    <p class="p2">
      已有账号？<a class="link" @click="showLogin">立即登录</a> 还没有账号？<a
        class="link"
        @click="showRegister"
        >立即注册</a
      >
    </p>
  </div>
</template>

<script>
import useStore from '@/store/index.js'
export default {
    data(){
			return {
			}
		},
    methods:{
      showLogin(){
        this.store.setCurComp('login');
      },
      showRegister(){
        this.store.setCurComp('register');
      },
    },
    created() { 
		  this.store = useStore();
    } 
};
</script>

<style lang="less" scoped>
.notlogin,
.notData {
  text-align: center;
  padding: 110px 0;
  .p1,
  .p2 {
    color: #606060;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
    .link {
      color: #f67f36;
    }
    img {
      margin-top: 90px;
    }
  }
}
</style>
